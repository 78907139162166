import React, { useState } from "react";
import TextMedia from "./textMedia";
import { FaPhotoVideo, FaPoll } from "react-icons/fa";
import { FaVideo } from "react-icons/fa6";
import { MdWork } from "react-icons/md";
import { CiStreamOn } from "react-icons/ci";
import { FaPodcast } from "react-icons/fa";
import { MdEvent } from "react-icons/md";
import { RiArticleLine } from "react-icons/ri";
import { FaBookOpen } from "react-icons/fa";
import { TbBookFilled } from "react-icons/tb";
import { AiOutlineSnippets } from "react-icons/ai";
import { TbLetterASmall } from "react-icons/tb";
const SocialMediaPost = ({ input }) => {
  const [activeTab, setActiveTab] = useState("All");
  const tabs = [
    { name: "All", icon: <TbLetterASmall /> },
    { name: "Text", icon: <FaPhotoVideo /> },
    { name: "Photo", icon: <FaPhotoVideo /> },
    { name: "Video", icon: <FaVideo /> },
    { name: "Job", icon: <MdWork /> },
    { name: "Article", icon: <RiArticleLine /> },
    { name: "Ebook", icon: <FaBookOpen /> },
    { name: "Course", icon: <TbBookFilled /> },
    { name: "Snippet", icon: <AiOutlineSnippets /> },
    { name: "Event", icon: <MdEvent /> },
    { name: "Polls", icon: <FaPoll /> },
    { name: "Podcast", icon: <FaPodcast /> },
    { name: "Live", icon: <CiStreamOn /> },
  ];

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        {tabs.map((tab) => (
          <div
            key={tab.name}
            onClick={() => setActiveTab(tab.name)}
            style={{
              textAlign: "center",
              cursor: "pointer",
            }}
          >
            <div
              style={{
                width: "50px",
                height: "50px",
                borderRadius: "50%",
                overflow: "hidden",
                border:
                  activeTab === tab.name
                    ? "4px solid purple"
                    : "4px solid transparent",
                transition: "border 0.3s",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "8px",
              }}
            >
              {tab.icon}
            </div>
            <div>{tab.name}</div>
          </div>
        ))}
      </div>
      <div style={{ marginTop: "20px", textAlign: "center" }}>
        {activeTab === "Text" ? (
          <TextMedia input={input} activeTab={activeTab} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default SocialMediaPost;
