/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import "./buttonStyle.css";
import "./jobs.css";
import iExp from "../../images/icons/exp.svg";
import iSalary from "../../images/icons/salary.svg";
import iLocation from "../../images/icons/location.svg";
import noData from "../../images/NoData.svg";
import iHome from "../../images/icons/home.svg";
import iBookmark from "../../images/icons/bookmark.svg";
import iBookmarkActive from "../../images/icons/bookmark-active.svg";
import Image from "../../images/company.png";
import axios from "axios";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import DeleteImg from "../../images/solar_shield-warning-bold-duotone.svg";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { ToastContainer, toast } from "react-toastify";
import ViewJobApplicant from "./jobApplication";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Common from "../../utils/common";
import { Amplify, Auth } from "aws-amplify";
import CloseIcon from "../../images/CloseIcon.svg";
import CloseIcons from "../../images/solar_close-circle-bold-duotone.svg";
import Upload from "../../images/Upload Minimalistic.svg";
import Favourite from "../../images/FavouriteSaved.png";
import Favourites from "../../images/Bookmark.png";
import Settings from "../settings";
import FitMe from "./fitme";
import CustomHeaderLayout from "./customHeaderLayout";
import ShimmerLoading from "../interview/shimmerloading";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";
Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:9f20ee67-2689-4af8-9d12-c9893279b334",
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: "us-east-1_sExLCIvAJ",
    userPoolWebClientId: "7hugeimfu8fnk38k4df5nb1jf1",
    oauth: {
      domain: "xakalpro.auth.us-east-1.amazoncognito.com",
      // redirectSignIn: "http://localhost:3000/",
      // redirectSignOut: "http://localhost:3000/",
      redirectSignIn: "https://qa.thexakal.com/login",
      redirectSignOut: "https://qa.thexakal.com/login",

      responseType: "token",
    },
  },
});
const HrLine = styled.div`
  border: 1px solid #f7f7f8;
  width: 100%;
  margin: 0;
`;

const Button2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Button3 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const Button5 = styled.div``;
const Button4 = styled.div`
  color: #2d3139;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px; /* 142.857% */
  display: flex;
  padding: 10px 24px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  border: 1px solid rgba(18, 19, 23, 0.1);
  cursor: pointer;
`;
const Button6 = styled.div`
  display: flex;
  height: 40px;
  padding: 12px 16px;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  background: rgba(253, 33, 69, 1);
  color: #fff;
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  line-height: 20px; /* 142.857% */
  cursor: pointer;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  // gap: 20px;
  padding: 5px;
`;

const Wrapper = styled.div``;
const JobDetail = styled.div``;

const JobTitle = styled.div``;

const IconTitle = styled.div``;
const DateWrapper = styled.div``;
const DateWrapperButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
`;
const QuestionLabel = styled.label`
  font-family: Inter-Medium, sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #4f4953;
`;
const PopUpTitle = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0.5px;
  text-align: left;
  color: #4f4953;
  margin: 0px 0 5px 0;
`;
const PopUpTitles = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 27px;
  letter-spacing: 0px;
  text-align: left;
  color: #362a41;
`;

const HrLines = styled.div`
  border: 1px solid #f7f7f8;
  margin: 10px 0;
`;

const CardWrapper = styled.div`
  cursor:pointer;
`;

const Text = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 16px;
  color: rgba(120, 118, 122, 1);
  font-weight: normal;
  line-height: 29px;
  letter-spacing: 0em;
  position: relative;
  text-align: center;
  margin: 10px 0 20px 0;
`;
const FileTypeInfo = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: left;
  color: #4f4953;
  // position: relative;
  // right: 35px;
  // bottom:40px;
  margin: 10px 0 0 10px;
  span {
    color: #ff596a;
  }
`;
const FileTypeInfos = styled.div`
  font-family: Inter-Medium, sans-serif;
  font-size: 12px;
  font-weight: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #4f4953;
  // margin-left: 5px;
  span {
    color: #ff596a;
    margin-top: 20px;
  }
`;
const FavouriteJob = () => {
  const [user, setUsers] = useState("");
  const [success, setSuccess] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const { state } = useLocation();
  const isCardActive = state?.isCardActive;
  const openNewJob = state?.openNewJob;
  const history = useHistory();

  const logout = () => {
    Common.logout();
  };
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUsers(user);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        setUsers(null);
      });
  }, []);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [favourites, setFavourites] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [isFavorite, setIsFavorite] = useState(false);
  const [loading, setLoading] = useState(false);
  const getAllJob = async () => {  
    setLoading(true); // Set loading state to true before making the API call  
  
    try {  
      const response = await axios.get(`${process.env.PUBLIC_URL}/xakal/get-all-favourites-apply`);  

      if (response.data) {  
       
        setFavourites(response.data);  
      }  
    } catch (error) {  
      console.error("Error fetching all jobs:", error); // Log the error for debugging  
    } finally {  
      setLoading(false); // Set loading state to false, whether the request was successful or not  
    }  
  };
  useEffect(() => {
    // getAllFinalJob();
    setLoading(true);
    getAllJob();
  }, []);
  const [selectedId, setSelectedId] = useState(null);
  const [userAppliedJob, setUserAppliedJob] = useState([]);
  const handleFunctionClose = async () => {  
    try {  
      const response = await axios.put(  
        `${process.env.PUBLIC_URL}/xakal/apply-submit/${selectedId}/${userId.userId}`  
      );  
  
      if (response.status === 200) {  
        // Update the favorite status in the state  
        setUserAppliedJob((prevSavedInterviews) => [  
          ...prevSavedInterviews,  
          selectedId,  
        ]);  
        toast.success("Job Applied Successfully...");  
      } else {  
        console.error("Failed to toggle favorite status");  
      }  
      
      setOpen3(false);  
      await getAllJob();  // Ensure the jobs are refreshed after applying  
    } catch (error) {  
      console.error("Error applying for the job:", error);  
    }  
  };
  const handleApplyClick = (id, questionList, applyThrough, urls) => {
    if (applyThrough === "companySite") {
      // Redirect to the specific URL
      window.location.href = urls;
    } else {
      setSelectedId(id); // Set the selected id in the state

      if (
        Array.isArray(questionList) &&
        questionList.length > 0 &&
        questionList.some((q) => q.questionName.trim() !== "")
      ) {
        // If there are questions, open the first modal
        setOpen(true);
        setSelectedQuestionList(questionList);
      } else {
        // If there are no questions, open the second modal
        setOpen2(true);
      }
    }
  };

  const [selectedQuestionList, setSelectedQuestionList] = useState([]);

  const [formData, setFormData] = useState({
    answers: [], // Store answers as an array of objects
  });

  const handleInputChange = (e, questionId) => {
    const newAnswers = { ...formData.answers };
    newAnswers[questionId] = { questionId, answer: e.target.value };
    setFormData({ answers: newAnswers });
  };
  const checkUnansweredMandatoryQuestions = () => {
    const unansweredMandatoryQuestions = selectedQuestionList.filter(
      (question) => {
        const answer = formData.answers[question._id];
        const isMandatory = question.isMandatory === true;
        const isStarred = question.isStarred === true;
        const condition1 = isMandatory && isStarred;
        const condition2 = isMandatory && !isStarred;
        const isUnanswered = !answer || answer.answer.trim() === "";
        return (condition1 || condition2) && isUnanswered;
      }
    );

    return unansweredMandatoryQuestions;
  };
  const handleSubmitOne = async () => {  
    try {  
      // Create an array of answers based on the questions  
      const answers = sortedQuestions.map((question) => ({  
        questionId: question._id,  
        answer: formData.answers[question._id]?.answer || "",  
      }));  
  
      // Check for unanswered mandatory questions  
      const unansweredMandatoryQuestions = checkUnansweredMandatoryQuestions();  
  
      if (unansweredMandatoryQuestions.length > 0) {  
        toast.error("Please answer all mandatory questions.");  
        return; // Exit the function early if there are unanswered questions  
      }  
  
      // Send the answers to the server  
      await axios.post(`${process.env.PUBLIC_URL}/xakal/add-answer/${selectedId}`, {  
        answers: answers, // Send the answers array  
      });  
  
      // Reset the form data after successful submission  
      setFormData({  
        ...formData,  
        answers: Array(formData.answers.length).fill(""),  
      });  
  
      // Handle the response here as needed  
      setOpen(false);  
      setOpen2(true);  
      
    } catch (error) {  
      console.error("Error submitting answers:", error); // Provide context for the error  
      toast.error("An error occurred while submitting your answers."); // User-friendly error message  
    }  
  };
  const [error2, setError2] = useState("");
  const [file, setFile] = useState(null);
  const handleFileChnage = (event) => {
    const selectedFile = event.target.files[0];
    const fileSizeLimit = 2 * 1024 * 1024; // 2MB
    const allowedFileTypes = ["application/pdf", "application/msword"];

    if (
      selectedFile &&
      selectedFile.size <= fileSizeLimit &&
      allowedFileTypes.includes(selectedFile.type)
    ) {
      setFile(selectedFile);
      setError2("");
    } else {
      // Display an error message or take any other action
      setError2(
        "File Size should not exceed 2mb and PDF,Word doc only allowed."
      );
    }

    // const selectedFile = event.target.files[0];
    // setFile(selectedFile);
  };
  const handleSubmitTwo = async () => {  
    // Check for errors before proceeding  
    if (error2 !== "") {  
      setError2("File Size should not exceed 2MB and PDF, Word doc only allowed.");  
      return; // Exit early if there's an error  
    }  
  
    const formData1 = new FormData();  
    formData1.append("image", file);  
  
    try {  
      // Upload the file  
      const uploadResponse = await axios.post(`${process.env.PUBLIC_URL}/xakal/files/`, formData1);  
      
      if (uploadResponse.data) {  
        const params = {  
          url: uploadResponse.data.url || "",  
        };  
  
        // Apply with the uploaded file URL  
        await axios.post(`${process.env.PUBLIC_URL}/xakal/apply/${selectedId}`, params);  
        
        // Successfully applied, handle UI updates  
        setOpen2(false);  
        setOpen3(true);  
        fetchData();  
      }  
    } catch (error) {  
      console.error("Error during file upload or applying:", error);  
      // Optionally provide user feedback  
      toast.error("An error occurred while processing your request.");  
    }  
  };
  const [dataOne, setDataOne] = useState();
  const [dataOneResume, setDataOneResume] = useState();
  const fetchData = async() => {
    try{
      const response = await axios.get(`${process.env.PUBLIC_URL}/xakal/jobs/${selectedId}/applicants/questions`);
      // .then((response) => {
        // Handle the response data here, e.g., set it in state or a variable.
        const questionList = response.data.job.questionList;
        const sortedQuestionList = questionList.sort((a, b) => {
          return b.starred - a.starred;
        });
        setDataOne(sortedQuestionList);
        setDataOneResume(response.data);
        // Continue with the rest of your logic, for example, call setOpen2 and setOpen3.
        setOpen2(false);
        setOpen3(true);
      }
      catch(error) {
        console.error("Error fetching data:", error);
      };
  };
  const [open4, setOpen4] = useState(false);
  const [userId, setUser] = useState({});

  useEffect(() => {
    // Define a function to fetch data
    const fetchData = async()=>{
    try{
      const response = await axios.get(`${process.env.PUBLIC_URL}/xakal/getuser`);
        setUser(response.data);
      }
      catch(error){console.error("Error fetching user data:",error)};
    }
    fetchData();
    // Call the fetchData function when the component mounts
  }, []);
  const handleWithdrawClick = async (jobId) => {
    // Make an API request to update the statusOne field to "withdrawn"
    try {
      // Send a PUT request to your backend to toggle the favorite status
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/withdraw/${jobId}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setUserAppliedJob((prevSavedInterviews) => {
          const index = prevSavedInterviews.indexOf(jobId);
          if (index !== -1) {
            return prevSavedInterviews.filter(
              (interviewId) => interviewId !== jobId
            );
          } else {
            return [...prevSavedInterviews, jobId];
          }
        });
      } else {
        console.error("Failed to toggle favorite status");
      }
      setOpen4(false);
      setFavouriteApplied(false);
      setUserApplied(false);
      toast.success("Job withdrawn successfully");
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };

  const currentDate = new Date();
  const [jobWithdrawId, setWithdrawIdToDelete] = useState(null);
  const toggleFavorite = async (id) => {
    try {
      // Send a PUT request to your backend to toggle the favorite status
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/get-manage-favourite/${id}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setSavedInterviews((prevSavedInterviews) => [
          ...prevSavedInterviews,
          id,
        ]);
      } else {
        console.error("Failed to toggle favorite status");
      }
      getAllJob();
      setActiveCard(null)

    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };
  const toggleFavoriteTwo = async (id) => {
    try {
      // Send a PUT request to your backend to toggle the favorite status
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/get-unfavourite/${id}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setSavedInterviews((prevSavedInterviews) => {
          const index = prevSavedInterviews.indexOf(id);
          if (index !== -1) {
            return prevSavedInterviews.filter(
              (interviewId) => interviewId !== id
            );
          } else {
            return [...prevSavedInterviews, id];
          }
        });
        getAllJob()
      setActiveCard(null)

      } else {
        console.error("Failed to toggle favorite status");
      }
      setIsOpen(false);
      setActiveCard(null)
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };
  const [openSetting, setOpenSetting] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedJobData, setSelectedJobData] = useState(null);

  const sortedQuestions = selectedQuestionList.slice().sort((a, b) => {
    if (a.isMandatory && a.isStarred && (!b.isMandatory || !b.isStarred)) {
      return -1; // A comes before B if A is both mandatory and starred, and B is not
    } else if (
      b.isMandatory &&
      b.isStarred &&
      (!a.isMandatory || !a.isStarred)
    ) {
      return 1; // B comes before A if B is both mandatory and starred, and A is not
    } else if (
      !a.isMandatory &&
      a.isStarred &&
      (b.isMandatory || !b.isStarred)
    ) {
      return -1; // A comes before B if A is not mandatory but starred, and B is mandatory or not starred
    } else if (
      !b.isMandatory &&
      b.isStarred &&
      (a.isMandatory || !a.isStarred)
    ) {
      return 1; // B comes before A if B is not mandatory but starred, and A is mandatory or not starred
    } else {
      return 0; // No change in order for other cases
    }
  });

  const currentYear = currentDate.getFullYear();

  const formattedYear = currentYear.toString();
  const handleWithdrawPopUpClick = async(jobId) => {
    // Make an API request to update the statusOne field to "withdrawn"
    try{
      await axios.put(`${process.env.PUBLIC_URL}/xakal/withdraw/${jobId}`);
        // Handle the response if needed
        await getAllJob();
        setOpen4(false);
        setIsOpen(false);
      }
      catch(error)  {
        console.error("Error withdrawing job:", error);
      };
  };
  const [savedInterview, setSavedInterviews] = useState([]);
  const [open5, setOpen5] = useState(false);
  const togglePopUpFavorite = async (id) => {
    try {
      // Send a PUT request to your backend to toggle the favorite status
      const response = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/get-manage-favourite/${id}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setSavedInterviews((prevSavedInterviews) => [
          ...prevSavedInterviews,
          id,
        ]);
      } else {
        console.error("Failed to toggle favorite status");
      }
      getAllJob();
      setIsOpen(false);
      setActiveCard(null);
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };
  const toggleUpdatePopFavoriteOne = async (id) => {
    try {
      // Send a PUT request to your backend to toggle the favorite status
      const response = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/get-unfavourite/${id}`
      );

      if (response.status === 200) {
        // Update the favorite status in the state
        setSavedInterviews((prevSavedInterviews) => {
          const index = prevSavedInterviews.indexOf(id);
          if (index !== -1) {
            return prevSavedInterviews.filter(
              (interviewId) => interviewId !== id
            );
          } else {
            return [...prevSavedInterviews, id];
          }
        });
      } else {
        console.error("Failed to toggle favorite status");
      }
      getAllJob();
      setIsOpen(false);
      setActiveCard(null);
    } catch (error) {
      console.error("Error updating favorite status:", error);
    }
  };
  const [userApplied, setUserApplied] = useState(false);
  const [favouriteApplied, setFavouriteApplied] = useState(false);
  const [activeCard, setActiveCard] = useState(null);
  const openPopup = (jobData) => {
    setSelectedJobData(jobData);
    setActiveCard(jobData._id);
    setShowMore(false);
    // Determine if the user has applied to this job
    const applicant = jobData.applicants.find(
      (applicant) => applicant.userId === userId.userId
    );
    const userApplied = applicant && applicant.statusOne === "Applied";

    // Determine if the user has favorited this job
    const favourite = jobData.favoritedBy.find(
      (fav) => fav.userId === userId.userId
    );
    const favouriteApplied =
      favourite && favourite.statusFavourite === "favourites";

    setUserApplied(userApplied);
    setFavouriteApplied(favouriteApplied);

    setIsOpen(true);
  };

  const TimeAgo = ({ timestamp }) => {
    const [timeAgo, setTimeAgo] = useState("");

    useEffect(() => {
      const calculateTimeAgo = () => {
        const currentTime = new Date();
        const postedTime = new Date(timestamp);
        const timeDifference = currentTime - postedTime;

        const minutes = Math.floor(timeDifference / (1000 * 60));
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
        const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));

        if (minutes < 60) {
          setTimeAgo(`${minutes} minute${minutes === 1 ? "" : "s"} ago`);
        } else if (hours < 24) {
          setTimeAgo(`${hours} hour${hours === 1 ? "" : "s"} ago`);
        } else if (days < 30) {
          setTimeAgo(`${days} day${days === 1 ? "" : "s"} ago`);
        } else if (months < 12) {
          setTimeAgo(`${months} month${months === 1 ? "" : "s"} ago`);
        } else {
          setTimeAgo(`${years} year${years === 1 ? "" : "s"} ago`);
        }
      };

      calculateTimeAgo();
    }, [timestamp]);

    return <span>{timeAgo}</span>;
  };
  const handleRemove = () => {
    setFile(null);
  };
  const handlePreview = () => {
    if (file) {
      if (
        file.type === "application/pdf" ||
        file.type === "application/msword"
      ) {
        return (
          <div>
            <embed
              src={URL.createObjectURL(file)}
              type={file.type}
              width="65px"
              height="50px"
            />
            <button
              onClick={handleRemove}
              style={{ position: "relative", bottom: "54px", left: "67px" }}
            >
              <img src={CloseIcons} alt="img" style={{ width: "20px" }} />
            </button>
          </div>
        );
      } else {
        return <p>File type not supported for preview</p>;
      }
    }
    // else {
    //   return <p>No file selected</p>;
    // }
  };
  const handlePreviews = () => {
    if (file) {
      if (
        file.type === "application/pdf" ||
        file.type === "application/msword"
      ) {
        return (
          <div>
            <embed
              src={URL.createObjectURL(file)}
              type={file.type}
              width="80px"
              height="50px"
            />
          </div>
        );
      } else {
        return <p>File type not supported for preview</p>;
      }
    }
  };
  const [isPopupOpen, setPopupOpen] = useState(false);
  const handleSave = (data) => {
    console.log("Form Data:", data);
    setPopupOpen(false); // Close the dialog if needed
  };
  // eslint-disable-next-line no-unused-vars
  const [isScrolled, setIsScrolled] = useState(false);


  const [dataValue, setDataValue] = useState();
  const getOneDetail = async() => {
    try{
      const response = await axios.get(`${process.env.PUBLIC_URL}/xakal/get-about-detail/${Common.loggedIn()}`);
        setDataValue(response.data.aboutOne);
      }
      catch(error) {
        console.error("Error fetching about detail;",error);
      };
  };
  useEffect(() => {
    getOneDetail();
  }, []);

  return (
    <div>
      <ToastContainer />
      <CustomHeaderLayout   activeStates={isCardActive}
          openNewPopup={openNewJob}/>
      <div className="job-card-app-wrap">
      <div className="job-card-container job-card-containers" style={{ paddingBottom: "30px" }}>
        {loading ? (
       <div style={{ top: "160px",
        position: "relative"} }>
         {Array.from({ length: 4 }).map((_, index) => (
         <ShimmerLoading key={index} />
       ))}
      </div>
        ) : favourites.length > 0 ? (
          favourites.map((i) => {
            const applicant = i.applicants.find(
              (applicant) => applicant.userId === userId.userId
            );

            const userApplied =
              applicant && applicant.statusOne === "Applied" ? true : false;
            console.log(userApplied, "userApplied");
            const favourite = i.favoritedBy.find(
              (fav) => fav.userId === userId.userId
            );

            const favouriteApplied =
              favourite && favourite.statusFavourite === "favourites"
                ? true
                : false;
            return (
              <div className="int-rows">
                <CardWrapper
                  className={isOpen ? "job-card-wrap" : "job-card-wrapper"}
                  onClick={() => openPopup(i)}
                >
                  <div
                    className={activeCard === i._id ? "underline" : ""}
                  ></div>
                  <div>
                    <ContentWrapper
                      className="job-content-wrapper"
                      onClick={() => openPopup(i)}
                    >
                      <div className="company-logo-container">
                        {i.companyName && i.companyName.companyLogo ? (
                          <img
                            src={i.companyName && i.companyName.companyLogo}
                            alt="img"
                            className="company-logo"
                          />
                        ) : (
                          <img src={Image} alt="img" className="company-logo" />
                        )}
                      </div>
                      <Wrapper className="job-title-wrapper">
                        <JobTitle
                          key={i._id}
                          onClick={() => openPopup(i)}
                          className="job-title"
                        >
                          {i.jobTitle}
                        </JobTitle>

                        <companyTitle className="company-title"   onClick={()=>history.push({pathname:`/portal/other-company-view/${i.companyName?.comID}`})}>
                          {i.companyName && i.companyName.value}
                        </companyTitle>
                        <p className="timestamp">
                          <TimeAgo timestamp={i.timestamps?.createdAt} />
                        </p>
                      </Wrapper>
                      <DateWrapper className="bookmark-btn-container date-toggle-wrapper">
                        {favouriteApplied || savedInterview.includes(i._id) ? (
                          <div onClick={(e) =>{
                            e.stopPropagation() 
                          toggleFavoriteTwo(i._id)}}>
                            <img
                              src={iBookmarkActive}
                              className="bookmark-icon"
                              alt="icon"
                            />
                          </div>
                        ) : (
                          <div onClick={(e) => 
                           { e.stopPropagation()
                          toggleFavorite(i._id)}}>
                            <img
                              src={iBookmark}
                              className="bookmark-icon"
                              alt="icon"
                            />
                          </div>
                        )}
                      </DateWrapper>
                    </ContentWrapper>
                    <HrLines />
                    <div>
                      <JobDetail className="job-details2">
                        <IconTitle className="icon-title">
                          <img src={iExp} className="jt-icon" alt="icon" />
                          <span>
                            {" "}
                            Exp{" "}
                            {i.experienceRequired2
                              ? `${i.experienceRequired} - ${i.experienceRequired2} yrs`
                              : i.experienceRequired
                                ? `${i.experienceRequired} yrs`
                                : ""}{" "}
                          </span>
                        </IconTitle>

                        <IconTitle className="icon-title">
                          <img src={iSalary} className="jt-icon" alt="icon" />
                          <span>{`${i.salaryFrom}L - ${i.salaryTo}L`}</span>
                        </IconTitle>
                        <IconTitle className="icon-title">
                          <img src={iLocation} className="jt-icon" alt="icon" />
                          <span>
                            {i.location?.substring(0, 8)}
                            {i.location?.length > 8 ? "..." : ""}
                          </span>
                        </IconTitle>

                        <IconTitle className="icon-title">
                          <img src={iHome} className="jt-icon" alt="icon" />
                          <span>{i.workMode}</span>
                        </IconTitle>
                      </JobDetail>
                    </div>
                  </div>
                </CardWrapper>
              </div>
            );
          })
        ) : (
          <img
            src={noData}
            className="job-content-wrapper nodata-center"
            alt="icon"
          />
        )}
      </div>
      {isOpen && selectedJobData ? (
        <div className="job-app-wrap job-app-wraps">
          <DialogContent style={{ padding: "0" }}>
            <ViewJobApplicant
              jobData={selectedJobData}
              setIsOpen={() => {
                setIsOpen(false);
                setActiveCard(false);
              }}
              showMore={showMore}
              setShowMore={()=>setShowMore(!showMore)}
            />
          </DialogContent>
          <div
            className="job-prev-btn"
          >
            {favouriteApplied ||
            savedInterview.includes(selectedJobData._id) ? (
              <>
                <img
                  src={Favourites}
                  alt="img"
                  style={{
                    width: "40px",
                    height: "40px",
                    padding: "4px",
                    margin: "0 10px",
                    background: "#fff",
                    border: "1px solid rgba(221, 223, 228, 0.50)",
                    borderRadius: "8px",
                  }}
                  onClick={() =>
                    toggleUpdatePopFavoriteOne(selectedJobData._id)
                  }
                />
              </>
            ) : (
              <img
                src={Favourite}
                alt="img"
                style={{
                  width: "40px",
                  height: "40px",
                  padding: "4px",
                  margin: "0 10px",
                  background: "#fff",
                  border: "1px solid rgba(221, 223, 228, 0.50)",
                  borderRadius: "8px",
                }}
                onClick={() => togglePopUpFavorite(selectedJobData._id)}
              />
            )}

            {userApplied || userAppliedJob.includes(selectedJobData._id) ? (
              <>
                <Button2
                  className="button3"
                  onClick={() => {
                    setWithdrawIdToDelete(selectedJobData._id);
                    setOpen4(true); // Open the modal when Delete is clicked
                  }}
                >
                  Withdraw
                </Button2>
              </>
            ) : (
              <button
                className="button3"
                onClick={() =>
                  handleApplyClick(
                    selectedJobData._id,
                    selectedJobData.questionList,
                    selectedJobData.applyThrough,
                    selectedJobData.urls
                  )
                }
              >
                Apply
              </button>
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      </div>
      <Dialog
        open={open}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <div className="modalHeader">
          <PopUpTitle>Assessment Questions</PopUpTitle>
        </div>
        <DialogContent>
          <div className="modalBody">
            {sortedQuestions
              .filter((question) => question.questionName.trim() !== "")
              .map((question, index) => (
                <div key={question.questionId} style={{ marginTop: "20px" }}>
                  <div>
                    <QuestionLabel htmlFor={`answer_${question._id}`}>
                      {index + 1}. {question.questionName}
                      {question.isMandatory && (
                        <span className="mandatory-star">*</span>
                      )}
                    </QuestionLabel>

                    <InputGroup className="mb-3">
                      <Form.Control
                        type="text"
                        id={`answer_${question._id}`}
                        name={`answer_${question._id}`}
                        value={
                          formData.answers[question._id]
                            ? formData.answers[question._id].answer
                            : ""
                        }
                        onChange={(e) => handleInputChange(e, question._id)}
                        style={{
                          borderRadius: "5px",
                          background: "rgba(54, 42, 65, 0.04)",
                          height: "48px",
                        }}
                        maxLength={250}
                      />
                    </InputGroup>
                  </div>
                </div>
              ))}
          </div>
          <div className="modalFooter">
            <button className="button5" onClick={() => setOpen(false)}>
              Cancel
            </button>
            <button
              className="button3"
              type="submit"
              onClick={() => handleSubmitOne(selectedId)}
            >
              Next
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open2}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px", width: "430px" } }}
      >
        <div className="modalHeader">
          <PopUpTitle className="modal-title-sub">
            Document Submission
          </PopUpTitle>
        </div>
        <DialogContent>
          <div className="modalBody" style={{ padding: "0px 20px" }}>
            <PopUpTitle className="">Resume</PopUpTitle>

            <InputGroup className="mb-3">
              <label
                for="file-upload"
                className="custom-file-upload"
                onChange={handleFileChnage}
              >
                <Form.Control
                  // className="custom-file-upload"
                  id="file-upload"
                  type="file"
                />
                {file === null ? (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <img src={Upload} alt="img" />
                    </div>

                    <div
                      style={{ display: "flex", width: "100%", padding: "0px" }}
                    >
                      <FileTypeInfos>
                        <span>*</span>Upload file size should be less than 2mb
                        (File type: Pdf, Doc)
                      </FileTypeInfos>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      gap: "60px",
                      alignItems: "center",
                    }}
                  >
                    <FileTypeInfo
                      style={{
                        // bottom: "77px",
                        borderRadius: "8px",
                        border: "1px solid #FFF",
                        width: "100px",
                        height: "65px",
                        padding: "5px 10px",
                        background:
                          "linear-gradient(0deg, rgba(0, 0, 0, 0.48) 0%, rgba(0, 0, 0, 0.48) 100%)",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                      }}
                    >
                      {handlePreview()}
                    </FileTypeInfo>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img src={Upload} alt="img" />
                    </div>
                  </div>
                )}
              </label>
              <span className="text-danger f12" style={{ marginLeft: "10px" }}>
                {error2}
              </span>
            </InputGroup>
          </div>
          <div className="modalFooter">
            <Button5 className="button5" onClick={() => setOpen2(false)}>
              Cancel
            </Button5>
            <button
              onClick={handleSubmitTwo}
              disabled={!file}
              className="button3"
            >
              Next
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open3}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <div className="modalHeader">
          <PopUpTitles>Application Preview</PopUpTitles>
        </div>
        <DialogContent>
          <div className="modalBody">
            <div
              style={{
                borderBottom: "1px solid #F4F4F6",
                paddingBottom: "10px",
              }}
            >
              {dataOne ? (
                <>
                  {dataOne &&
                    dataOne.map((question, index) => {
                      const userAnswers = question.answers.filter(
                        (answer) => answer.userId === userId.userId
                      );

                      // Check if there are any user answers
                      if (userAnswers.length > 0) {
                        const lastUserAnswer =
                          userAnswers[userAnswers.length - 1];

                        return (
                          <>
                            <PopUpTitles>{`${index + 1}. ${
                              question.questionName
                            }`}</PopUpTitles>
                            <div>
                              <PopUpTitle>{`Answer: ${
                                lastUserAnswer.answer || "No answer provided"
                              }`}</PopUpTitle>
                            </div>
                          </>
                        );
                      }
                    })}
                </>
              ) : (
                ""
              )}
            </div>
            <div style={{ position: "relative", marginTop: "20px" }}>
              <PopUpTitles>Documents Submitted</PopUpTitles>

              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  background: "#F4F4F6",
                  height: "88px",
                  borderRadius: "8px",
                }}
              >
                <FileTypeInfo
                  style={{
                    top: "10px",
                    borderRadius: "8px",
                    border: "1px solid #FFF",
                    width: "100px",
                    height: "65px",
                    padding: "5px 10px",
                    background:
                      "linear-gradient(0deg, rgba(0, 0, 0, 0.48) 0%, rgba(0, 0, 0, 0.48) 100%)",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  {handlePreviews()}
                </FileTypeInfo>

                <a
                  href={
                    dataOneResume ? dataOneResume.resume : "No resume provided"
                  }
                  target="_blank"
                  rel="noreferrer"
                  style={{ fontweight: "normal", marginTop: "35px" }}
                >
                  {`${dataValue && dataValue.firstName} ${
                    dataValue && dataValue.lastName
                  }_resume_${formattedYear}`}
                </a>
              </div>
            </div>
          </div>
          <div className="modalFooter">
            <Button5 className="button5" onClick={() => setOpen(false)}>
              Cancel
            </Button5>
            <Button3
              className="button3"
              type="submit"
              onClick={handleFunctionClose}
            >
              Submit
            </Button3>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open4}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            borderRadius: "8px",
            top: "10px",
            transform: "translateY(0%)",
            position: "absolute",
            backgroundColor: "rgba(255, 218, 224, 1)",
            padding: "5px 8px",
            // height: "56px",
            overflow: "hidden",
          },
        }}
      >
        <DialogContent
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        >
          <JobTitle
            style={{
              fontFamily: "Inter-Medium,sans-serif",
              fontSize: "14px",
              fontweight: "normal",
              lineHeight: "37px",
              gap: "10px",
              display: "flex",
            }}
          >
            <div
              style={{
                width: "40px",
                height: "40px",
                borderRadius: "8px",
                gap: " 8px",
                background: "white",
              }}
            >
              <img
                src={DeleteImg}
                style={{ marginTop: "2px", marginLeft: "9px" }}
                alt="img"
              />
            </div>
            Are you sure you want to withdraw ?
          </JobTitle>
          <DialogActions>
            <div
              style={{
                marginLeft: "20px",
                display: "flex",
                gap: "10px",
              }}
            >
              <Button4
                onClick={() => setOpen4(false)}
                style={{ cursor: "pointer" }}
              >
                No Thanks
              </Button4>
              <Button6 onClick={() => handleWithdrawClick(jobWithdrawId)}>
                Proceed
              </Button6>
            </div>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openSetting}
        PaperProps={{
          className: "setting-modal",
        }}
        className="modal-container"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <Settings open={() => setOpenSetting(false)} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={open5}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
          }}
        >
          <PopUpTitle>Application withdrawal</PopUpTitle>
          <AiOutlineCloseCircle onClick={() => setOpen5(false)} />
        </DialogTitle>
        <DialogContent>
          <Text>Are you sure you want to withdraw your Application?</Text>

          <DateWrapperButton>
            <Button5
              onClick={() => setOpen4(false)}
              style={{ cursor: "pointer" }}
            >
              No
            </Button5>
            <Button3 onClick={() => handleWithdrawPopUpClick(jobWithdrawId)}>
              Yes
            </Button3>
          </DateWrapperButton>
        </DialogContent>
      </Dialog>
      <Dialog
        open={success}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium,sans-serif",
          }}
        ></DialogTitle>
        <DialogContent>
          <Text>Are you sure you want to logout?</Text>

          <DateWrapperButton>
            <button className="button5" onClick={() => setSuccess(false)}>
              No
            </button>
            {user ? (
              <button
                className="button3"
                onClick={() => {
                  Auth.signOut();
                  Common.logout();
                }}
              >
                Yes
              </button>
            ) : (
              <button className="button3" onClick={logout}>
                Yes
              </button>
            )}
          </DateWrapperButton>
        </DialogContent>
      </Dialog>
      <Dialog
        open={isPopupOpen}
        halfWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle className="modalHeader">
          <span className="mTitle">Job</span>
          <img src={CloseIcon} alt="img" onClick={() => setPopupOpen(false)} />
        </DialogTitle>
        <HrLine />
        <DialogContent>
          <FitMe onSave={handleSave} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default FavouriteJob;
