import React, { useEffect, useState } from "react";
import axios from "axios";
import Common from "../../utils/common";
import styled from "styled-components";
import DesktopHeader from "../desktopHeader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import imageAvatar from "../../images/company.png";
import ShimmerLoading from "../interview/shimmerloading";
const InvitationCard = styled.div`
  background: #ffffff;
  border-radius: 12px;
  padding: 12px;
  height: 120px;
  display: flex;
  gap: 24px;
  margin-bottom: 16px; /* Adjust the value as needed */
`;
const InvitationCards = styled.div`
  display: flex;
  gap: 24px;
  margin: 15px auto;
`;
const Img = styled.img`
  width: 72px;
  height: 72px;
  border-radius: 50%;
  margin: auto 0;
`;

const Title = styled.div`
  width: auto;
  height: 22px;
  top: 24px;
  left: 111px;
  font-family: Inter-Medium, sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  color: #8653b6;
`;

const DetailsWrapper = styled.div`
  padding: 8px 0;
`;

const MyRequests = () => {
  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [requests, setRequests] = useState([]);

  const getMyRequest = async () => {
    return await axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get/associates/${Common.loggedIn()}`
      )
      .then((res) => {
        console.log("my request", res?.data);
        setRequests(res?.data);
      });
  };
  useEffect(() => {
    getMyRequest();
  }, []);

  const BackToCompany = () => {
    history.goBack();
  };
  ///myRequest

  const [invitations, setInvitations] = useState([]);
  const [loading, setLoading] = useState(false);
  const getMyInvitations = async () => {
    setLoading(true);
    await axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get/myInvitations/${Common.loggedIn()}`
      )
      .then((res) => {
        console.log("my invitations", res?.data);
        const dt = res?.data?.filter((e) => e.connectionStatus === "PENDING");
        setInvitations(dt);
        setLoading(false);
      });
  };
  console.log("invitations", invitations);

  const updateInvitation = async (status, id) => {
    return await axios
      .post(`${process.env.PUBLIC_URL}/xakal/update/invitation`, {
        invitedUserId: id,
        associateId: Common.loggedIn(),
        connectionStatus: status,
      })
      .then((res) => {
        console.log("update Invitation", res?.data);
        getMyInvitations();
      });
  };
  useEffect(() => {
    getMyInvitations();
  }, []);
  return (
    <div>
      <div className="header-layouts">
        <DesktopHeader
          title={"Connection Requests"}
          isSearchActive={false}
          backbtn
          back={BackToCompany}
        />
      </div>
      <div
        style={{
          margin: "2rem",
          display: "grid",
          justifyContent: "center",
          gap: "16px",
        }}
      >
        {loading ? (
          <div style={{ top: "120px", position: "relative" }}>
            {Array.from({ length: 4 }).map((_, index) => (
              <ShimmerLoading key={index} />
            ))}
          </div>
        ) : (
          <>
            {invitations?.map((invitation, idx) => (
              <InvitationCard key={idx}>
                <Img
                  src={
                    invitation?.details?.avatar
                      ? invitation?.details?.avatar
                      : imageAvatar
                  }
                  alt=""
                />
                <DetailsWrapper>
                  <Title>
                    {invitation?.details?.firstName +
                      " " +
                      invitation?.details?.lastName}
                  </Title>
                  <InvitationCards>
                    <button
                      className="button3"
                      onClick={() =>
                        updateInvitation("ASSOCIATE", invitation?.appUserId)
                      }
                    >
                      Accept
                    </button>
                    <button
                      className="button5"
                      onClick={() =>
                        updateInvitation("REJECTED", invitation?.appUserId)
                      }
                    >
                      Decline
                    </button>
                  </InvitationCards>
                  <p></p>
                </DetailsWrapper>
              </InvitationCard>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
export default MyRequests;
