/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import "./textMedia.css";
import companyLogo from "../../images/profile-image.svg";
import Slider from "react-slick";
import axios from "axios";
const CardPost = ({ filteredPosts, input, getAll }) => {
  const [openComment, setOpenComment] = useState();
  const [newComment, setNewComment] = useState("");
  const handleCommentChange = (e) => {
    setNewComment(e.target.value);
  };
  const TimeAgo = ({ timestamp }) => {
    const [timeAgo, setTimeAgo] = useState("");
    useEffect(() => {
      const calculateTimeAgo = () => {
        const currentTime = new Date();
        const postedTime = new Date(timestamp);
        const timeDifference = currentTime - postedTime;
        const minutes = Math.floor(timeDifference / (1000 * 60));
        const hours = Math.floor(timeDifference / (1000 * 60 * 60));
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const months = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 30));
        const years = Math.floor(timeDifference / (1000 * 60 * 60 * 24 * 365));
        if (minutes < 60) {
          setTimeAgo(`${minutes} minute${minutes === 1 ? "" : "s"} ago`);
        } else if (hours < 24) {
          setTimeAgo(`${hours} hour${hours === 1 ? "" : "s"} ago`);
        } else if (days < 30) {
          setTimeAgo(`${days} day${days === 1 ? "" : "s"} ago`);
        } else if (months < 12) {
          setTimeAgo(`${months} month${months === 1 ? "" : "s"} ago`);
        } else {
          setTimeAgo(`${years} year${years === 1 ? "" : "s"} ago`);
        }
      };
      calculateTimeAgo();
    }, [timestamp]);

    return <span className="mes-time">{timeAgo}</span>;
  };
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const handleComment = (postId) => {
    setOpenComment((prevId) => (prevId === postId ? null : postId));
  };

  const handleCommentSubmit = async (id) => {
    try {
      // Prepare media uploads

      const payload = {
        commentText: newComment,
        createdBy: input.userId,
      };

      const res = await axios.put(
        `${process.env.PUBLIC_URL}/xakal/update-social-comment/${id}`,
        payload
      );

      // Reset form states
      setOpenComment(false);
      setNewComment("");
      getAll();
      console.log(res.data, "datasocial");
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };
  return (
    <>
      {filteredPosts.length > 0 ? (
        filteredPosts.map((post) => (
          <div className="cardContainers" style={{ margin: "20px 0" }}>

            <>
              <div key={post._id}>
                <div className="contentDiv" style={{ gap: "10px" }}>
                  <img
                    src={
                      post.aboutDetail?.avatar
                        ? post.aboutDetail?.avatar
                        : companyLogo
                    }
                    alt="Profile"
                    className="profileImage"
                  />
                  <div className="textContents">
                    {post.aboutDetail.firstName} {post.aboutDetail.lastName}
                  </div>
                  <div>
                    <TimeAgo timestamp={post.postedBy.createdAt} />
                  </div>
                </div>
              </div>
              <div className="text-post-content">{post?.text}</div>
              {post.media && post.media.length > 0 && (
                <Slider {...settings} className="image-carousel">
                  {post.media.map((image, index) => (
                    <div key={index} className="carousel-slide">
                      <img src={image.url} alt={`Post Image ${index}`}/>
                    </div>
                  ))}
                </Slider>
              )}
              <div className="interactions">
                {/* <button className="like-button">Like</button> */}
                <button className="comment-button" onClick={() => handleComment(post._id)}>
                  Comment
                </button>
              </div>
              {openComment === post._id && (
                <div>
                  {post.comments.map((ind) => {
                    return (
                      <div>
                        <div className="inputComment">
                          <img
                            src={
                              ind?.aboutDetail?.avatar
                                ? ind?.aboutDetail?.avatar
                                : companyLogo
                            }
                            alt="Profile"
                            className="profileImage"
                          />
                          <p>{ind?.text}</p>
                          <TimeAgo timestamp={ind?.createdAt} />
                        </div>
                      </div>
                    );
                  })}

                  <div>
                    <div className="inputComment">
                      <img
                        src={input?.avatar ? input?.avatar : companyLogo}
                        alt="Profile"
                        className="profileImage"
                      />
                      <input
                        type="text"
                        placeholder="What's on your mind?"
                        className="inputField"
                        value={newComment}
                        onChange={handleCommentChange}
                      />
                      <button
                        className="like-button"
                        onClick={() => handleCommentSubmit(post._id)}
                      >
                        Send
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        ))
      ) : (
        <p>No posts available.</p>
      )}
    </>
  );
};

export default CardPost;
