import { toast } from "react-toastify";
import React, { useState, useEffect, useRef } from "react";
import "./textMedia.css";
import companyLogo from "../../images/profile-image.svg";
import { Dialog, DialogContent } from "@mui/material";
import Toggle from "react-toggle";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import Calender from "../../images/Calendar.svg";
import axios from "axios";
import Common from "../../utils/common";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaPhotoVideo, FaPoll } from "react-icons/fa";
import { FaVideo } from "react-icons/fa6";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdWork } from "react-icons/md";
import { IoMdArrowDropdown } from "react-icons/io";
import { CiStreamOn } from "react-icons/ci";
import { FaPodcast } from "react-icons/fa";
import { MdEvent } from "react-icons/md";
import { RiArticleLine } from "react-icons/ri";
import { FaBookOpen } from "react-icons/fa";
import { TbBookFilled } from "react-icons/tb";
import { AiOutlineSnippets } from "react-icons/ai";
const TextCard = ({ activeTab, input, getAll }) => {
  const [inputValue, setInputValue] = useState("");
  const [invitations, setInvitations] = useState([]);
  const [openToggle, setOpenToggle] = useState(false);
  const [scheduleToggle, setScheduleToggle] = useState(false);
  const [openSchedulePost, setOpenSchedulePost] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTimezone, setSelectedTimezone] = useState();
  const [showDatePicker, setShowDatePicker] = useState(false); // Controls date picker visibility
  const [privacy, setPrivacy] = useState("ASSOCIATE");
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [openMore, setOpenMore] = useState(false);
  const handleMore = () => {
    setOpenMore((prevState) => !prevState);
  };
  const fileInputRef = useRef(null);
  const fileInputVideoRef = useRef(null);

  const timezones = moment.tz.names().map((tz) => {
    const offsetInMinutes = moment.tz(tz).utcOffset();
    const hours = Math.floor(Math.abs(offsetInMinutes) / 60)
      .toString()
      .padStart(2, "0"); // Ensures two digits
    const minutes = (Math.abs(offsetInMinutes) % 60)
      .toString()
      .padStart(2, "0"); // Ensures two digits
    const sign = offsetInMinutes >= 0 ? "+" : "-";
    const formattedOffset = `GMT ${sign}${hours}:${minutes}`;

    return {
      name: `${tz} (${formattedOffset})`,
      label: `${tz} (${formattedOffset})`,
    };
  });
  const formattedDate = selectedDate
    ? `${selectedDate.toLocaleDateString()} ${selectedDate.toLocaleTimeString()}`
    : null;
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleToggle = () => {
    setOpenToggle(true);
  };

  const handleToggleOne = () => {
    setScheduleToggle((prevState) => !prevState);
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
    // Do something with the selected date
  };
  const [open, setOpen] = useState(false);
  const renderCustomHeader = ({ date, decreaseMonth, increaseMonth }) => (
    <div className="custom-datepicker-header">
      <button onClick={decreaseMonth}>{"<"}</button>
      <span>{date.toLocaleDateString()}</span>
      <button onClick={increaseMonth}>{">"}</button>
      <div style={{ display: "flex", gap: "20px", left: "10px" }}>
        <button onClick={() => setOpen(false)} style={{ fontSize: "20px" }}>
          Back
        </button>
      </div>
    </div>
  );
  const handlePrivacyChange = () => {
    setPrivacy((prevPrivacy) =>
      prevPrivacy === "ASSOCIATE" ? "PUBLIC" : "ASSOCIATE"
    );
  };
  const formatDate = (date) => {
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };
  const currentDate = new Date();
  const formattedDates = formatDate(currentDate);
  const handleSubmit = async () => {
    try {
      // Prepare media uploads
      const mediaUploads = [];

      // Handle image uploads if there are any
      if (images.length > 0) {
        const imageUploads = await Promise.all(
          images.map(async (file) => {
            const formData = new FormData();
            formData.append("image", file);

            // Upload image and get the URL
            const response = await axios.post(
              `${process.env.PUBLIC_URL}/xakal/files/multi-social`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            return response.data.urls.map((url) => ({
              type: file.type,
              url,
            }));
          })
        );

        mediaUploads.push(...imageUploads.flat()); // Flatten and add to mediaUploads
      }

      // Handle video uploads if there are any
      if (videos.length > 0) {
        const videoUploads = await Promise.all(
          videos.map(async (file) => {
            const formData = new FormData();
            formData.append("video", file);

            // Upload video and get the URL
            const response = await axios.post(
              `${process.env.PUBLIC_URL}/xakal/files/multi-social`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            );

            return response.data.urls.map((url) => ({
              type: file.type,
              url,
            }));
          })
        );

        mediaUploads.push(...videoUploads.flat()); // Flatten and add to mediaUploads
      }

      const payload = {
        category: activeTab,
        text: inputValue,
        privacy: privacy,
        schedulePost: {
          schedulePost: scheduleToggle ? "Scheduled" : "Immediate",
          dateTime: scheduleToggle
            ? selectedDate.toISOString()
            : formattedDates,
          timezone: selectedTimezone,
        },
        media: mediaUploads, // Set media to the uploaded media array
      };

      const res = await axios.post(
        `${process.env.PUBLIC_URL}/xakal/create-social-post`,
        payload
      );

      // Reset form states
      setSelectedDate(new Date());
      setSelectedTimezone();
      setPrivacy("ASSOCIATE");
      setInputValue("");
      setImages([]); // Clear images after submission
      setVideos([]); // Clear videos after submission
      setOpenToggle(false);
      setScheduleToggle(false);
      setOpenSchedulePost(false);
      getAll();
      console.log(res.data, "datasocial");
    } catch (error) {
      console.error("Error during submission:", error);
    }
  };

  const getMyInvitations = async () => {
    await axios
      .get(
        `${process.env.PUBLIC_URL}/xakal/get/myInvitations/${Common.loggedIn()}`
      )
      .then((res) => {
        console.log("my invitations", res?.data);
        const dt = res?.data?.filter((e) => e.connectionStatus === "ASSOCIATE");
        setInvitations(dt);
      });
  };
  useEffect(() => {
    getMyInvitations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (invitations.length > 0) {
      getAll();
    }
  }, [invitations]);

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    // Filter for valid image formats (png, jpg, gif, jpeg)
    const validImages = files.filter(
      (file) =>
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/gif"
    );

    // Check for invalid file formats
    const invalidFiles = files.filter(
      (file) =>
        file.type !== "image/png" &&
        file.type !== "image/jpeg" &&
        file.type !== "image/jpg" &&
        file.type !== "image/gif"
    );

    if (invalidFiles.length > 0) {
      toast.error("Only PNG, JPG, GIF, and JPEG formats are allowed.");
      return;
    }

    // Calculate the total size of selected images
    const totalSize = validImages.reduce((acc, file) => acc + file.size, 0);

    if (totalSize > 12 * 1024 * 1024) {
      // 12 MB
      toast.error(
        "Total image size exceeds 12 MB. Please select fewer images."
      );
      return;
    }

    setImages((prevImages) => [
      ...prevImages,
      ...validImages, // Store raw files for upload
    ]);
  };

  const handleVideoChange = (e) => {
    const files = Array.from(e.target.files);

    // Log the selected file types for debugging
    console.log("Selected files:", files);

    // Filter for valid video formats (mp4 and mp3)
    const validVideos = files.filter(
      (file) => file.type === "video/mp4" || file.type === "audio/mpeg"
    );

    // Check for invalid file formats
    const invalidFiles = files.filter(
      (file) => file.type !== "video/mp4" && file.type !== "audio/mpeg"
    );

    // If any invalid files are present, show an error
    if (invalidFiles.length > 0) {
      toast.error("Only MP4 and MP3 formats are allowed.");
      return;
    }

    // Calculate the total size of selected videos
    const totalSize = validVideos.reduce((acc, file) => acc + file.size, 0);

    // Check if the total size exceeds 200 MB
    if (totalSize > 200 * 1024 * 1024) {
      toast.error(
        "Total video size exceeds 200 MB. Please select fewer videos."
      );
      return;
    }

    // If everything is valid, update the state with the new videos
    setVideos((prevVideos) => [...prevVideos, ...validVideos]);
  };

  // In your render method:

  const handleDivClick = () => {
    fileInputRef.current.click();
  };
  const handleDivVideoClick = () => {
    fileInputRef.current.click();
  };
  const removeImage = (index) => {
    setImages(images.filter((_, i) => i !== index));
  };
  const removeVideo = (index) => {
    setVideos(images.filter((_, i) => i !== index));
  };
  return (
    <>
      <ToastContainer />
      <div className="cardContainer">
        <div className="contentDiv">
          <img
            src={input?.avatar ? input?.avatar : companyLogo}
            alt="Profile"
            className="profileImage"
          />
          <div className="textContent">
            {inputValue?.length > 0 ? (
              <textarea
                value={inputValue}
                placeholder="What's on your mind?"
                className="inputField"
                onChange={handleInputChange}
                rows={3}
              />
            ) : (
              <input
                type="text"
                placeholder="What's on your mind?"
                className="inputField"
                value={inputValue}
                onChange={handleInputChange}
              />
            )}
          </div>
        </div>
        <div className="character">{inputValue?.length}/3000 Characters</div>
        <div className="optionsContainer">
          <div
            className="option"
            onClick={handleDivClick}
            style={{
              cursor: "pointer",
              padding: "10px",
              background: "#f0f0f0",
              border: "1px solid #ccc",
            }}
          >
            <FaPhotoVideo size={20} />
            Photo
          </div>
          <input
            type="file"
            accept="image/*"
            multiple
            ref={fileInputRef}
            style={{ display: "none" }} // Hide the input
            onChange={handleImageChange}
          />
          <div
            className="option"
            onClick={handleDivVideoClick}
            style={{
              cursor: "pointer",
              padding: "10px",
              background: "#f0f0f0",
              border: "1px solid #ccc",
            }}
          >
            <FaVideo size={20} />
            Video
          </div>
          <input
            type="file"
            accept="video/*"
            multiple
            ref={fileInputVideoRef}
            style={{ display: "none" }} // Hide the input
            onChange={handleVideoChange}
          />
          <div
            className="option"
            style={{
              cursor: "pointer",
              padding: "10px",
              background: "#f0f0f0",
              border: "1px solid #ccc",
            }}
          >
            <MdWork size={20} />
            Job
          </div>
          <div
            className="option"
            onClick={handleMore}
            style={{
              cursor: "pointer",
              padding: "10px",
              background: "#f0f0f0",
              border: "1px solid #ccc",
            }}
          >
            {openMore ? (
              <>
                <IoMdArrowDropdown size={20} />
                <span style={{ marginLeft: "5px" }}>Less</span>
              </>
            ) : (
              <>
                <RxHamburgerMenu size={20} />
                <span style={{ marginLeft: "5px" }}>More</span>
              </>
            )}
          </div>
        </div>
        {openMore && (
          <>
            <div className="optionsContainer">
              <div
                className="option"
                onClick={handleDivClick}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                }}
              >
                <RiArticleLine size={20} />
                Article
              </div>

              <div
                className="option"
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                }}
              >
                <FaBookOpen size={20} />
                Course
              </div>

              <div
                className="option"
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                }}
              >
                <TbBookFilled size={20} />E Book
              </div>
              <div
                className="option"
                onClick={handleDivVideoClick}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                }}
              >
                <AiOutlineSnippets size={20} />
                Snippet
              </div>
            </div>
            <div className="optionsContainer">
              <div
                className="option"
                onClick={handleDivClick}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                }}
              >
                <MdEvent size={20} />
                Event
              </div>

              <div
                className="option"
                onClick={handleDivVideoClick}
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                }}
              >
                <FaPoll size={20} />
                Polls
              </div>

              <div
                className="option"
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                }}
              >
                <FaPodcast size={20} />
                Podcast
              </div>
              <div
                className="option"
                style={{
                  cursor: "pointer",
                  padding: "10px",
                  background: "#f0f0f0",
                  border: "1px solid #ccc",
                }}
              >
                <CiStreamOn size={20} />
                Live
              </div>
            </div>
          </>
        )}
        {images.length > 0 && (
          <div>
            <h2>Preview:</h2>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {images.map((image, index) => (
                <div
                  key={index}
                  style={{ position: "relative", margin: "5px" }}
                >
                  <img
                    src={URL.createObjectURL(image)}
                    alt={`Preview ${index}`}
                    style={{
                      maxWidth: "150px",
                      maxHeight: "150px",
                      borderRadius: "4px",
                    }}
                  />
                  <button
                    onClick={() => removeImage(index)}
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      background: "rgba(255, 255, 255, 0.7)",
                      border: "none",
                      borderRadius: "50%",
                      cursor: "pointer",
                      padding: "2px",
                    }}
                  >
                    ✖️
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
        {videos.length > 0 && (
          <div>
            <h2>Preview:</h2>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {videos.map((image, index) => (
                <div
                  key={index}
                  style={{ position: "relative", margin: "5px" }}
                >
                  <img
                    src={URL.createObjectURL(image)}
                    alt={`Preview ${index}`}
                    style={{
                      maxWidth: "150px",
                      maxHeight: "150px",
                      borderRadius: "4px",
                    }}
                  />
                  <button
                    onClick={() => removeVideo(index)}
                    style={{
                      position: "absolute",
                      top: "5px",
                      right: "5px",
                      background: "rgba(255, 255, 255, 0.7)",
                      border: "none",
                      borderRadius: "50%",
                      cursor: "pointer",
                      padding: "2px",
                    }}
                  >
                    ✖️
                  </button>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="buttonDiv">
          <button className="button1" onClick={handleToggle}>
            Next
          </button>
        </div>
      </div>
      <Dialog
        open={openToggle}
        PaperProps={{ className: "dialogPaper" }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <div className="dialogContent">
            <button className="button1" onClick={() => setOpenToggle(false)}>
              Back
            </button>
          </div>
          <div className="toggleContainer">
            <span> Private</span>
            <Toggle
              onChange={handlePrivacyChange}
              checked={privacy === "PUBLIC"}
            />
            <span> Public</span>
          </div>
          <div className="dialogButtonContainer">
            <button
              className="button1"
              onClick={() => setOpenSchedulePost(true)}
            >
              Next
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openSchedulePost}
        PaperProps={{ className: "dialogPapers" }}
        fullWidth
        maxWidth="sm"
      >
        <DialogContent>
          <div className="dialogContent">
            <button
              className="button1"
              onClick={() => setOpenSchedulePost(false)}
            >
              Back
            </button>
          </div>
          <h4>Schedule Post</h4>
          <div className="toggleContainer">
            <div>
              <span>Post Immediately</span>
              <Toggle onChange={handleToggleOne} checked={scheduleToggle} />
              <span>Schedule Post</span>
            </div>
          </div>
          {scheduleToggle && (
            <div>
              <div className="scheduleOptions">
                <div className="scheduleTime">
                  {showDatePicker && (
                    <DatePicker
                      selected={selectedDate}
                      onChange={handleDateChange}
                      showTimeSelect
                      timeFormat="HH:mm aa"
                      timeIntervals={15}
                      dateFormat="MMMM d, yyyy h:mm aa"
                      open={showDatePicker}
                      onCalendarOpen={() => setShowDatePicker(true)}
                      onCalendarClose={() => setShowDatePicker(false)}
                      renderCustomHeader={renderCustomHeader}
                    />
                  )}

                  <label>Date and Time</label>
                  <div
                    className={
                      showDatePicker ? "schedule-foot" : "schedule-footer"
                    }
                  >
                    <button
                      className="button5"
                      style={{ width: "160px" }}
                      onClick={() => setShowDatePicker(true)}
                    >
                      <img src={Calender} alt="img" style={{ width: "30px" }} />
                      Schedule
                    </button>
                  </div>
                </div>
              </div>
              <div className="scheduleOptions">
                <div className="scheduleTime">
                  <label>Time Zone</label>
                  <select
                    value={selectedTimezone}
                    onChange={(e) => setSelectedTimezone(e.target.value)}
                    className="timezoneDropdown"
                  >
                    {timezones.map((tz) => (
                      <option key={tz.name} value={tz.name}>
                        {tz.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}
          <div className="dialogButtonContainer">
            <button className="button1" onClick={handleSubmit}>
              Post
            </button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TextCard;
