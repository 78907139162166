import React, { useEffect } from "react";
import { useState } from "react";
import "../styles/login.css";
import "../styles/utils.css";
import "./Jobs/buttonStyle.css";
import MobileHeader from "./mobileHeader";
import DesktopHeader from "./desktopHeader";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import ToasterMessage from "./Toaster Message/toaster";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { DialogTitle } from "@mui/material";
import styled from "styled-components";
import { PiEyeLight, PiEyeSlashLight } from "react-icons/pi";
import { Amplify, Auth } from "aws-amplify";
import Common from "../utils/common";
import Settings from "./settings";
import Back from "../images/Back.svg";

const Button3 = styled.div`
  background-color: #362a41;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: white;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: white;
    color: #362a41;
    border: 1px solid #362a41;
  }
`;
const Button5 = styled.div`
  background-color: white;
  border-radius: 10px;
  border: 1px solid #362a41;
  font-weight: normal;
  font-size: 18px;
  color: #362a41;
  padding: 13px 45px;
  margin: 15px 0;
  cursor: pointer;
  &:hover {
    background: #362a41;
    color: white;
    border: 1px solid #362a41;
  }
`;
const DateWrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;
const HeaderLayout = styled.div``;

const FormContainer = styled.div``;
const ChangePassword = styled.div``;
const Span = styled.div`
  font-family: Inter-Medium,sans-serif;
  font-size: 18px;
  font-weight: normal;
  line-height: normal;
  color: #362A41;
  text-align: left;
`;
const Text = styled.div`
  font-family: Inter-Medium, Sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  color: #78767A;
  margin: 20px 0 20px 25px;
  font-style: normal;
`;
Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:9f20ee67-2689-4af8-9d12-c9893279b334",
    region: "us-east-1",
    identityPoolRegion: "us-east-1",
    userPoolId: "us-east-1_sExLCIvAJ",
    userPoolWebClientId: "7hugeimfu8fnk38k4df5nb1jf1",
    oauth: {
      domain: "xakalpro.auth.us-east-1.amazoncognito.com",
      // redirectSignIn: "http://localhost:3000/",
      // redirectSignOut: "http://localhost:3000/",
      redirectSignIn: "https://qa.thexakal.com/login",
      redirectSignOut: "https://qa.thexakal.com/login",

      responseType: "token",
    },
  },
});
const Forgetpassword = (props) => {
  const [user, setUsers] = useState("");
  const [success4, setSuccess4] = useState(false);
  const logout = () => {
    Common.logout();
  };
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user) {
          setUsers(user);
        }
      })
      .catch((error) => {
        console.log("Error: ", error);
        setUsers(null);
      });
  }, []);

  const [valid, setValid] = useState(false);
  const [success, setSuccess] = useState(false);
  const [type, setType] = useState(true);
  const [type2, setType2] = useState(true);
  const [type3, setType3] = useState(true);
  const [error2, setError2] = useState(null);
  const [error3, setError3] = useState(null);

  const [input, setInput] = useState({
    currentPassword: "",
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    currentPassword: "",
    password: "",
    confirmPassword: "",
  });
  // eslint-disable-next-line no-unused-vars
  const [showToaster, setShowToaster] = useState(false);
  const [passwordVal, setPasswordValidation] = useState({
    displayVal: "none",
    //startWithLetter:false,
    uppercase: false,
    lowercase: false,
    specialChar: false,
    numeral: false,
    minchar: false,
    valid: false,
  });

  const onInputChange = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));

    // validateInput(e);
    // if(input.currentPassword === ""){
    //   setError3("Please Enter Current Password")
    // }
    // else{
    //   setError3(null)
    // }
  };

  const onInputChanges = (e) => {
    const { name, value } = e.target;
    setInput((prev) => ({
      ...prev,
      [name]: value,
    }));

    validateInput(e);
    validateInput2(e);
    validateInput3(e);
    validateInput4(e);
    validateInput5(e);
    validateInput6(e);
  };

  const uppercaseRegExp = /(?=.*?[A-Z])/;
  const lowercaseRegExp = /(?=.*?[a-z])/;
  const digitsRegExp = /(?=.*?[0-9])/;
  const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
  const minLengthRegExp = /.{8,}/;

  const validateInput = (e) => {
    let { name, value } = e.target;
    setError((prev) => {
      const stateObj = { ...prev, [name]: "" };

      switch (name) {
        case "password":
          const uppercasePassword = uppercaseRegExp.test(value);
          const lowercasePassword = lowercaseRegExp.test(input.password);
          const digitsPassword = digitsRegExp.test(value);
          const specialCharPassword = specialCharRegExp.test(value);
          const minLengthPassword = minLengthRegExp.test(value);

          if (!value) {
            stateObj[name] = "Please enter password.";
          } else if (input.confirmPassword && value !== input.password) {
            stateObj[name] =
              "Re-entered password doesn't match with the previous password.";
          } else if (!uppercasePassword) {
            stateObj[name] = "At least one Uppercase";
          } else if (!lowercasePassword) {
            stateObj[name] = "At least one Lowercase";
          } else if (!digitsPassword) {
            stateObj[name] = "At least one digit";
          } else if (!specialCharPassword) {
            stateObj[name] = "At least one special characters";
          } else if (!minLengthPassword) {
            stateObj[name] = "At least minumum 8 characters";
          } else {
            stateObj["confirmPassword"] = input.confirmPassword
              ? ""
              : error.confirmPassword;
          }
          break;

        case "confirmPassword":
          if (!value) {
            stateObj[name] = "Please enter confirm password.";
          } else if (input.password && value !== input.password) {
            stateObj[name] =
              "Re-entered password doesn't match with the previous password.";
          }
          break;
        case "currentPaassword":
          if (!value) {
            stateObj[name] = "Please enter current password.";
          }
          break;
        default:
          break;
      }
      if (
        stateObj[name] === "" &&
        input.currentPassword !== "" &&
        input.password !== "" &&
        input.confirmPassword !== "" &&
        error2 === null
      ) {
        setValid(true);
      } else {
        setValid(false);
      }
      return stateObj;
    });
  };
  const Eye = () => {
    setType(!type);
  };
  const Eye2 = () => {
    setType2(!type2);
  };
  const Eye3 = () => {
    setType3(!type3);
  };
  const validateInput2 = (e) => {
    let value = e.target.value;
    const uppercasePassword = uppercaseRegExp.test(value);

    if (uppercasePassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, uppercase: true };
      });
    } else if (!uppercasePassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, uppercase: false };
      });
    }
  };

  const validateInput3 = (e) => {
    let value = e.target.value;
    const lowercasePassword = lowercaseRegExp.test(value);

    if (lowercasePassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, lowercase: true };
      });
    } else if (!lowercasePassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, lowercase: false };
      });
    }
  };

  const validateInput4 = (e) => {
    let value = e.target.value;
    const digitsPassword = digitsRegExp.test(value);

    if (digitsPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, numeral: true };
      });
    } else if (!digitsPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, numeral: false };
      });
    }
  };

  const validateInput5 = (e) => {
    let value = e.target.value;
    const specialCharPassword = specialCharRegExp.test(value);

    if (specialCharPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, specialChar: true };
      });
    } else if (!specialCharPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, specialChar: false };
      });
    }
  };

  const validateInput6 = (e) => {
    let value = e.target.value;
    const minLengthPassword = minLengthRegExp.test(value);

    if (minLengthPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, minchar: true };
      });
    } else if (!minLengthPassword) {
      setPasswordValidation((passwordVal) => {
        return { ...passwordVal, minchar: false };
      });
    }
  };

  const history = useHistory();

  const submitRegister = async(e) => {
    e.preventDefault();
    // setDisable("submitted");
    // if (input.currentPassword === "") {
    //   setError3("Please Enter Current Password");
    // }
    try{
      const response = await axios
      .post(`${process.env.PUBLIC_URL}/xakal/get-update`, {
        currentPassword: input.currentPassword,
      })

        if (response.data.success === false) {
          if (input.currentPassword === "") {
            setError2(null);
            setError3("Please enter current password");
          } else {
            console.log("true");
            setError3(null);
            setError2(response.data.error);
            setValid(false);
          }
        } else if (response.data.success === true) {
          setError2(null);
          // setValid(true);
          console.log(input.password);
        } else {
          setError2(false);
          setValid(false);
          // toast.error(response.response.data.error);
        }
        console.log(response);
      }
      catch(error){console.log("Error fetching details:",error)}

    // history.push("/portal/new-password");
  };

  const handleFunction = async() => {
    try{
      const response = await axios

      .put(`${process.env.PUBLIC_URL}/xakal/update-password`, input)

        console.log(response);
        console.log(input.password);
        // window.location.href = "/"
      }
      catch(error){console.log("Error fetching details:",error)}
    //  history.push("");
    // window.location.href = "/portal/dashboard";
    setSuccess(false);
    // setShowToaster(true);
    toast.success(
      "Your password has been changed and your account has been logged out from all other devices!"
    );
    setTimeout(() => {
      history.push("/portal/jobs");
    }, 4000);
  };

  function handleSubmit(e) {
    e.preventDefault();
    setSuccess(true);
  }
  const [openSetting, setOpenSetting] = useState(false);

  const BackBtn = () => {
    history.push("./jobs");
  }
  const searchedKey = () => { }
  return (
    <>
      {showToaster && (
        <ToasterMessage
          message="Your password has been changed and your account has been logged out from all other devices!"
          duration={3000}
        />
      )}
      <ToastContainer />
      <HeaderLayout>
        <MobileHeader onlyLogo={true} />
        {/* End of Mobile Header  */}
        {/* Start of desktop Header  */}
        <div className="header-layouts change-head">
          <DesktopHeader title={"Change Password"} searchedKey={searchedKey} isSearchActive={false} back={BackBtn} backbtn />
        </div>
        {/* End of desktop Header  */}
      </HeaderLayout>

      <div className="p-15 bg-m-white in-content-wrapper">
        <div className="f-pass-container">
          <div className="w-100">
            <div className="sub-header o-mobile">
              <img
                src={Back}
                alt="img"
                style={{ cursor: "pointer" }}
                onClick={() => history.push("/portal/jobs")}
              />

              <h4 className="textpass font-face-gm mb-0">Change Password</h4>
            </div>
            <div className="cp-wrapper">
              <h4 className="cp-header o-desk">Change your password here</h4>
              <FormContainer className="cp-body">
                <div>
                  <ChangePassword className="textpass font-face-gm">
                    Current Password
                  </ChangePassword>
                  <div
                    className="wrap-input100"
                    style={{ marginBottom: "20px" }}
                  >
                    <div style={{ position: "relative" }}>
                      <input
                        autoComplete="off"
                        type={type ? "password" : "text"}
                        className="input101 font"
                        name="currentPassword"
                        placeholder="Enter password"
                        value={input.currentPassword}
                        onChange={onInputChange}
                        style={{ fontFamily: "Inter-Medium,sans-serif"}}
                        onBlur={submitRegister}
                      />
                      <div className="pass-eye-container">
                        {type ? (
                          <PiEyeSlashLight size={30} onClick={Eye} />
                        ) : (
                          <PiEyeLight size={30} onClick={Eye} />
                        )}
                      </div>
                    </div>
                    <p className="text-danger mb-0 f12">{error2}</p>
                    {error3 && <p className="text-danger mb-0 f12">{error3}</p>}
                  </div>

                  <div
                    className="mb-m-10"
                    style={{
                      position: "relative",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Link to="./forget-password" className="forgot-password">
                      Forgot password ?
                    </Link>
                  </div>

                  <ChangePassword className="textpass font-face-gm">
                    New Password
                  </ChangePassword>
                  <div className={"wrap-input100"}>
                    <div className="pass-input-wrapper">
                      <input
                        autoComplete="off"
                        type={type2 ? "password" : "text"}
                        className={"input101 font"}
                        name="password"
                        placeholder="Enter password"
                        value={input.password}
                        style={{ fontFamily: "Inter-Medium,sans-serif" }}
                        onChange={onInputChanges}
                        onBlur={validateInput}
                      />
                      <div className="pass-eye-container">
                        {type2 ? (
                          <PiEyeSlashLight size={30} onClick={Eye2} />
                        ) : (
                          <PiEyeLight size={30} onClick={Eye2} />
                        )}
                      </div>
                    </div>

                    {error.password && (
                      <p className="text-danger f12">{error.password}</p>
                    )}
                  </div>

                  <ChangePassword className="textpass font-face-gm">
                    Re-type Password
                  </ChangePassword>
                  <div className={"wrap-input100 m-b-30"}>
                    <div className="pass-input-wrapper">
                      <input
                        autoComplete="off"
                        type={type3 ? "password" : "text"}
                        className={"input101 font"}
                        name="confirmPassword"
                        placeholder="Enter confirm password"
                        style={{ fontFamily: "Inter-Medium,sans-serif" }}
                        value={input.confirmPassword}
                        onChange={onInputChange}
                        onBlur={validateInput}
                        disabled={error.password}
                      />
                      <div className="pass-eye-container">
                        {type3 ? (
                          <PiEyeSlashLight size={30} onClick={Eye3} />
                        ) : (
                          <PiEyeLight size={30} onClick={Eye3} />
                        )}
                      </div>
                    </div>
                    {error.confirmPassword && (
                      <span className="text-danger f12">
                        {error.confirmPassword}
                      </span>
                    )}
                  </div>

                  <div className="container-login100-form-btn mb-submit">
                    <button
                      type="submit"
                      className="login100-form-btn"
                      disabled={!valid || error2}
                      style={{ opacity: valid ? "100%" : "50%" }}
                      onClick={handleSubmit}
                    >
                      Change Password
                    </button>
                  </div>
                </div>

              </FormContainer>
              {error.password ? (
                <div>
                  <div id="message7" className="pass-instruct">
                    <div
                      style={{
                        position: "relative",
                        marginLeft: "12px",
                      }}
                    >
                      <p className="pass-hint-head">
                        Password must contain the following
                      </p>

                      <div className="pass-in-check">
                        <div
                          className={
                            passwordVal.lowercase ? "check" : "uncheck"
                          }
                        ></div>
                        <p
                          className={
                            passwordVal.lowercase ? "valid" : "invalid"
                          }
                        >
                          Must have 1 lower case alphabet
                        </p>
                      </div>

                      <div className="pass-in-check">
                        <div
                          className={
                            passwordVal.uppercase ? "check" : "uncheck"
                          }
                        ></div>
                        <p
                          className={
                            passwordVal.uppercase ? "valid" : "invalid"
                          }
                        >
                          Must have 1 upper case alphabet
                        </p>
                      </div>

                      <div className="pass-in-check">
                        <div
                          className={passwordVal.numeral ? "check" : "uncheck"}
                        ></div>
                        <p
                          id="number"
                          className={passwordVal.numeral ? "valid" : "invalid"}
                        >
                          Must have 1 numeral case alphabet
                        </p>
                      </div>

                      <div className="pass-in-check">
                        <div
                          className={passwordVal.minchar ? "check" : "uncheck"}
                        ></div>
                        <p
                          id="length"
                          className={passwordVal.minchar ? "valid" : "invalid"}
                        >
                          Minimum 8 characters required
                        </p>
                      </div>

                      <div className="pass-in-check">
                        <div
                          className={
                            passwordVal.specialChar ? "check" : "uncheck"
                          }
                        ></div>
                        <p
                          className={
                            passwordVal.specialChar ? "valid" : "invalid"
                          }
                        >
                          Must have 1 special character [#?!@$%^&*-]
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={openSetting}
        PaperProps={{
          style: {
            width: "347px",
            height: "200px",
            top: "50px",
            right: "35px",
            borderRadius: "24px",
            position: "fixed",
          },
        }}
        fullWidth
        maxWidth="sm"
      >
        {/* <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
            position: "relative",
          }}
        >
          <span
            style={{
              width: "347px",
              height: "50px",
              padding: "19px, 16px, 19px, 25px",
              border: "0px, 0px, 1px, 0px",
              borderBottom: "1px solid #F4F4F6",
            }}
          >
            Setting
          </span>
          <AiOutlineCloseCircle onClick={() => setOpenSetting(false)} />
        </DialogTitle> */}
        <DialogContent>
          <Settings open={() => setOpenSetting(false)} />
          {/* <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            <BsCreditCard2Front size={20} />
            <p onClick={() => history.push("/portal/changepassword")}>
              Change Password
            </p>
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium",
              gap: "10px",
              cursor: "pointer",
            }}
          >
            {/* <CiUser size={20} />
            <p onClick={() => history.push("/portal/my-profile")}>
              My Profile
            </p> 
          </div>
          <div
            style={{
              display: "flex",
              position: "relative",
              fontFamily: "Inter-Medium",
              gap: "10px",
              cursor: "pointer",
            }}
            onClick={onSubmit}
          >
            <MdOutlineLogout size={20} style={{ color: "#FF5252" }} />
            <p style={{ color: "#FF5252" }}> Logout</p>
          </div> */}
        </DialogContent>
      </Dialog>
      <Dialog open={success} fullWidth maxWidth="sm" PaperProps={{ width: "auto", }}>
        <DialogTitle
          className="modalHeader"
        >
          <Span style={{marginTop:"15px", marginLeft:"10px",padding:"15px",}}>
            
          Are you sure you want to proceed?
          </Span>
          {/* <AiOutlineCloseCircle
            onClick={() => setSuccess(false)}
            style={{ cursor: "pointer" }}
          /> */}
        </DialogTitle>
        <DialogContent>
          <Text className="" style={{ padding: "30px",marginLeft: "-5px",}}>
          Changing the password will logout your account from all other
            devices.
          </Text>

        </DialogContent>
        <div className="modalFooter" style={{ display: "flex", gap: "20px" }}>
          <button className="button5" style={{ width: "70px", height: "43px" }} onClick={() => setSuccess(false)}>
            NO
          </button>

          <button className="modal-btn" style={{ width: "70px", height: "43px" }} onClick={handleFunction}>
            YES
          </button>
        </div>
      </Dialog>
      <Dialog
        open={success4}
        fullWidth
        maxWidth="sm"
        PaperProps={{ style: { borderRadius: "20px" } }}
      >
        <DialogTitle
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "Inter-Medium",
          }}
        ></DialogTitle>
        <DialogContent>
          <Text>Are you sure you want to logout?</Text>

          <DateWrapperButton>
            <Button5 onClick={() => setSuccess4(false)}>No</Button5>
            {user ? (
              <Button3
                onClick={() => {
                  Auth.signOut();
                  Common.logout();
                }}
              >
                Yes
              </Button3>
            ) : (
              <Button3 onClick={logout}>Yes</Button3>
            )}
          </DateWrapperButton>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Forgetpassword;
